export const PRODUCTS = [
  {
    name: 'blueprism.controlcenter',
    displayName: 'Control Center',
    version: '1.0',
  },
  {
    name: 'blueprism.designstudio',
    displayName: 'Design Studio',
    version: '1.0',
  },
  {
    name: 'blueprism.processrepository',
    displayName: 'Process Repository',
    version: '1.0',
  },
  {
    name: 'blueprism.remotedigitalworker',
    displayName: 'Remote Digital Worker',
    version: '1.0',
  },
];
