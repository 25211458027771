import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { FormLayout, SubmitButton } from '@blueprism/nextgen-platform-components';
import { FormContent, FormButtons } from '@blueprism/ui-pattern-forms';

import { useLicensingDrawerContext } from '../../../../context';
import { FromToPicker } from '../../../Fields';

export function AddLicensingFormBody() {
  const { formatMessage } = useIntl();
  const { resetForm } = useFormikContext();
  const { drawerState } = useLicensingDrawerContext();

  useEffect(() => {
    if (!drawerState) {
      resetForm();
    }
  }, [drawerState]);

  return (
    <FormLayout>
      <FormContent>
        <FromToPicker />
      </FormContent>

      <FormButtons justify="center">
        <SubmitButton label={formatMessage({ id: 'account.licensing.details.drawer.form.button.label' })} />
      </FormButtons>
    </FormLayout>
  );
}
