import styled, { css } from 'styled-components';

import { Box, Row } from '@blueprism/ui-core';

export const SubDomainLabel = styled(Box)(
  ({ theme }) => css`
    position: relative;

    & > p {
      position: absolute;
      color: ${theme.color.disabled};
      margin-top: 3.75rem;
      margin-left: ${theme.globalSizes.xs};
    }
  `,
);

export const StyledRow = styled(Row)(
  ({ theme }) => css`
    & > p {
      margin-top: ${theme.globalSizes.xxs};
    }
  `,
);
