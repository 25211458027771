import styled from 'styled-components';

import { Placeholder, Text } from '@blueprism/ui-core';

export const StyledPlaceholder = styled(Placeholder)<{ marginBottom?: string }>`
  display: block;
  margin-bottom: ${(props) => props.marginBottom};
`;

export const StyledTitleText = styled(Text)`
  max-width: 85%;
  word-wrap: break-word;
`;
