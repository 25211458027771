import { useProductsApi } from 'pages/Account/components/Products/hooks';

import type { UseErrorBoundary } from 'types';

export function useProductApi(accountId: string, productId: string, throwOnError: UseErrorBoundary) {
  const { activatedProducts, isLoading } = useProductsApi(accountId, throwOnError);

  const product = activatedProducts.find(({ id }) => id === productId);

  return {
    productName: product?.name || '',
    isProductLoading: isLoading,
  };
}
