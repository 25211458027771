import { useContext } from 'react';

import { LicensingContext } from '../LicensingProvider';

export function useLicensingContext() {
  const licensingDrawerData = useContext(LicensingContext);

  if (licensingDrawerData === undefined) {
    throw new Error('useLicensingContext must be within LicensingProvider scope');
  }

  return licensingDrawerData;
}
