import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { GET_TENANT_ADMIN_DETAILS } from './api';

import type { AccountAdminDetailsFormData } from '../../types';
import type { AccountAdminDataResponse, GetAccountAdminVariablesRequest } from './api';

export function useAccountAdminDetailsApi(tenantId: string) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix, managementRegionsLoading } = useRegionContext();

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetAccountAdminDetails, tenantId, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<AccountAdminDataResponse, GetAccountAdminVariablesRequest>(
        managementRegionPrefix,
        GET_TENANT_ADMIN_DETAILS,
        {
          tenantId,
        },
      ),
    enabled: !!tenantId && !!managementRegionPrefix,
  });

  const tenantAdminDetails = data?.tenantAdminDetails || ({} as AccountAdminDetailsFormData);
  const isLoading = isFetching || managementRegionsLoading;

  return { isLoading, tenantAdminDetails };
}
