import { useIntl } from 'react-intl';

import { Button, Description, Row, Term, Text } from '@blueprism/ui-core';

import { useAccountAdminStatus } from './hooks';
import { StatusFieldPlaceholder } from './components';

import type { AccountAdminStatus } from '../../constants';

export function AccountAdminStatusField({ status, userId }: { status: AccountAdminStatus; userId: string }) {
  const { formatMessage } = useIntl();

  const { handleStatusAction, userStatus, buttonLabel, showStatusButton, isLoading } = useAccountAdminStatus(
    status,
    userId,
  );

  const showStatus = userStatus && !isLoading;

  return (
    <>
      <Term fontWeight="black">{formatMessage({ id: 'account.details.status.label' })}</Term>
      <Description>
        <Row gap="large" align="center">
          {showStatus ? (
            <>
              <Text>{userStatus}</Text>
              {showStatusButton && (
                <Button disabled={isLoading} onClick={handleStatusAction}>
                  {buttonLabel}
                </Button>
              )}
            </>
          ) : (
            <StatusFieldPlaceholder />
          )}
        </Row>
      </Description>
    </>
  );
}
