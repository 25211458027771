import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { FormLayout, SubmitButton } from '@blueprism/nextgen-platform-components';
import { FormContent, FormButtons, TextFormField } from '@blueprism/ui-pattern-forms';

import { useLicensingDrawerContext } from '../../../../context';
import { LicenseDrawerStates } from '../../../../constants';
import { FromToPicker } from '../../../Fields';

import { createValidation } from './validationSchema';

export function AddMeteringFormBody() {
  const { formatMessage } = useIntl();
  const { resetForm } = useFormikContext();
  const { drawerState } = useLicensingDrawerContext();

  const { valueSchema } = createValidation(formatMessage);

  useEffect(() => {
    if (!drawerState) {
      resetForm();
    }
  }, [drawerState]);

  return (
    <FormLayout>
      <FormContent>
        <FromToPicker />

        {drawerState === LicenseDrawerStates.Metering && (
          <TextFormField required requiredLabel="Required" name="value" label="Value" validate={valueSchema} />
        )}
      </FormContent>

      <FormButtons justify="center">
        <SubmitButton label={formatMessage({ id: 'account.licensing.details.drawer.form.button.label' })} />
      </FormButtons>
    </FormLayout>
  );
}
