import { gql } from 'graphql-request';

export const GET_SKUS = gql`
  query SkusByProduct($productId: Uuid!) {
    skusByProduct(request: { productId: $productId }) {
      edges {
        node {
          name
          code
          licenses {
            displayName
          }
          meters {
            identifier
            displayName
            limit
            editableLimit
          }
        }
      }
    }
  }
`;
