import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { GET_SKUS } from './api';

import type { GetSkusResponse, GetSkusVariablesRequest } from './api';

export function useSkusApi(productId: string) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix } = useRegionContext();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetProductSkus, productId, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<GetSkusResponse, GetSkusVariablesRequest>(managementRegionPrefix, GET_SKUS, {
        productId,
      }),
    enabled: !!productId && !!managementRegionPrefix,
  });

  const skus = data?.skusByProduct?.edges.map(({ node }) => node) || [];

  return { isLoading, skus };
}
