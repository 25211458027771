import { gql } from 'graphql-request';

export const GET_TENANT_DETAILS = gql`
  query GetTenantDetails($id: Uuid!) {
    tenant(id: $id) {
      adminFirstName
      adminLastName
      managementLocation
      concurrencyStamp
      domainName
      masterClientEmail
      id
      name
      status
      isAuthenticationConfigured
      isLicenseConfigured
      isMeterConfigured
      isDomainConfigured
    }
  }
`;
