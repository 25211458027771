import type { PropsWithChildren } from '@blueprism/nextgen-platform-components';

import { ProductActivationForm } from './components';
import { ProductActivationDrawerProvider } from './context';

import type { ProductActivationFormDrawerProps } from './types';

export function ProductActivationFormDrawer({
  children,
  productConfigurationPage,
}: PropsWithChildren<ProductActivationFormDrawerProps>) {
  return (
    <ProductActivationDrawerProvider productConfigurationPage={productConfigurationPage}>
      {children}
      <ProductActivationForm />
    </ProductActivationDrawerProvider>
  );
}
