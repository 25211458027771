import { gql } from 'graphql-request';

export const GET_RUNNING_DOMAIN_QUERY = gql`
  query RunningDomain {
    runningDomain {
      domain
      maxTenantSubdomainLevel
    }
  }
`;
