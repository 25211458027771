import { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';
import { useToast, ToastType } from '@blueprism/ui-pattern-toasts';

import { Routes } from 'routes/constants';
import { getManagementRegionPrefix } from 'services/region';

import { PRODUCTS } from '../../mocks';
import { useLicensingContext, useLicensingDrawerContext } from '../../context';
import { formatDateToString } from '../../utils';
import { OperationLevel } from '../../constants';

import { ADD_TENANT_LICENSES } from './api';

import type { License } from 'types/entities';
import type { FormikHelpers } from 'formik';
import type { AddTenantLicensesVariables, AddTenantLicensesResponse } from './api';

export function useLicensingCreationApi() {
  const { requestWithPrefix } = useApiClient();
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const { triggerToast } = useToast();

  const [isLicenseAdded, setIsLicenseAdded] = useState(false);
  const { closeDrawer } = useLicensingDrawerContext();
  const { setLicenses, licenses, currentTenantId = '', currentTenantRegion = '' } = useLicensingContext();
  const productId = formatMessage({ id: 'account.licensing.productLicensing.hybridHostingRPA.label' });

  function handleRedirectToLicensing(tenantId?: string) {
    push(Routes.getLicensingUrl(tenantId));
  }

  function handleRedirectToGrid() {
    push(Routes.Account);
  }

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (variables: AddTenantLicensesVariables) => {
      const managementRegionPrefix = getManagementRegionPrefix(currentTenantRegion);

      return requestWithPrefix<AddTenantLicensesResponse>(managementRegionPrefix, ADD_TENANT_LICENSES, variables);
    },
    meta: {
      badRequestToastContent: {
        title: formatMessage({ id: 'account.notification.licensesCreationFailed' }),
        description: formatMessage({ id: 'account.notification.licensesCreationFailed.message' }),
      },
    },
  });

  async function handleSubmit({ startDate, endDate }: Partial<License>, actions: FormikHelpers<License>) {
    const licensesPayload = PRODUCTS.map((product) => ({
      startDate: formatDateToString(startDate),
      endDate: formatDateToString(endDate),
      operationLevel: OperationLevel.AccountWide,
      ...product,
    }));

    try {
      const response = await mutateAsync({
        tenantId: currentTenantId,
        productId,
        licenses: licensesPayload,
      });

      const accountData = response.addTenantLicenses;

      triggerToast({
        type: ToastType.SUCCESS,
        title: formatMessage({ id: 'account.notification.licensesCreated' }),
        description: formatMessage({ id: 'account.notification.licensesCreated.message' }, { productId }),
      });

      setLicenses({
        ...licenses,
        licensing: accountData?.entity?.licenses?.[0],
      });

      setIsLicenseAdded(true);
      actions.resetForm();
      closeDrawer();
    } catch (error) {
      handleRedirectToLicensing(currentTenantId);
    }
  }

  return { isLoading, handleSubmit, handleRedirectToGrid, isLicenseAdded };
}
