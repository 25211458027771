import { DOMAIN_NAME_DISALLOWED_CHARACTERS_TO_REPLACE, DOMAIN_NAME_SUBDOMAIN_MAX_LENGTH } from '../constants';

export function generateDomainName(tenantName: string) {
  if (!tenantName) return undefined;

  const domainName = tenantName
    .replace(DOMAIN_NAME_DISALLOWED_CHARACTERS_TO_REPLACE, '')
    .trim()
    .replace(/ /g, '-')
    .toLowerCase();

  if (!domainName) return undefined;

  if (domainName.length > DOMAIN_NAME_SUBDOMAIN_MAX_LENGTH) return undefined;

  return domainName;
}

export function getDomainNameMaxLength(maxSubdomainLevel: number) {
  return DOMAIN_NAME_SUBDOMAIN_MAX_LENGTH * maxSubdomainLevel + (maxSubdomainLevel - 1);
}
