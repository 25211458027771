import { createContext, useState } from 'react';

import type { PropsWithChildren } from '@blueprism/nextgen-platform-components';

import type { LicensingDrawerContextState } from './types';
import type { LicenseDrawerStates } from '../constants';

export const LicensingDrawerContext = createContext<LicensingDrawerContextState>({
  drawerState: null,
  openDrawer: () => {},
  closeDrawer: () => {},
});

export function LicensingDrawerProvider({ children }: PropsWithChildren) {
  const [drawerState, setDrawerState] = useState<LicenseDrawerStates | null>(null);

  function openDrawer(drawer: LicenseDrawerStates | null) {
    setDrawerState(drawer);
  }

  function closeDrawer() {
    setDrawerState(null);
  }

  return (
    <LicensingDrawerContext.Provider value={{ drawerState, openDrawer, closeDrawer }}>
      {children}
    </LicensingDrawerContext.Provider>
  );
}
