import { useCallback } from 'react';

import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { FormLayout, CancelButton, Form, SubmitButton } from '@blueprism/nextgen-platform-components';
import { FormButtons, FormContent, TextFormField } from '@blueprism/ui-pattern-forms';

import { useTenantDetailsApi } from 'pages/Account/hooks';
import { useAccountMultiRegionRouting } from 'hooks/';

import { useTenantDetailsEditApi } from './hooks';
import { createValidation } from './validationSchema';

import type { AccountEditFormData, DetailsRouteParams } from './types';

export function TenantDetailsForm() {
  const { accountId: id, mode } = useParams<DetailsRouteParams>();
  const { formatMessage } = useIntl();
  const { goToAccountDetails } = useAccountMultiRegionRouting();

  const { tenant, isLoading } = useTenantDetailsApi(id);
  const { handleSubmit } = useTenantDetailsEditApi(id);

  const initialValues: AccountEditFormData = {
    name: tenant.name,
    domainName: tenant.domainName,
    managementLocation: tenant.managementLocation,
  };

  const viewModeActive = mode !== 'edit';

  const handleOnCancelClick = useCallback(() => {
    goToAccountDetails(tenant.id);
  }, [tenant]);

  const { nameSchema } = createValidation(formatMessage);

  return (
    <Form
      enableReinitialize
      initialValues={initialValues}
      loading={isLoading}
      onSubmit={handleSubmit}
      viewMode={viewModeActive}
      onCloseEditMode={handleOnCancelClick}
    >
      <FormLayout>
        <FormContent>
          <TextFormField
            name="name"
            validate={nameSchema}
            label={formatMessage({ id: 'account.details.companyName.label' })}
          />
          <TextFormField disabled name="domainName" label={formatMessage({ id: 'account.details.domainName.label' })} />
          <TextFormField
            disabled
            name="managementLocation"
            label={formatMessage({ id: 'account.details.managementLocation.label' })}
          />
        </FormContent>
        <FormButtons>
          <CancelButton onCancel={handleOnCancelClick} label={formatMessage({ id: 'buttons.cancel' })} />
          <SubmitButton label={formatMessage({ id: 'buttons.save' })} />
        </FormButtons>
      </FormLayout>
    </Form>
  );
}
