import { gql } from 'graphql-request';

export const ACTIVATE_SKU_IN_TENANT = gql`
  mutation ActivateSkuInTenant(
    $tenantId: Uuid!
    $productId: Uuid!
    $skuCode: String!
    $from: DateTime!
    $to: DateTime!
    $meterData: [MeterDataInput!]!
    $concurrencyStamp: Uuid!
  ) {
    activateSkuInTenant(
      input: {
        tenantId: $tenantId
        productId: $productId
        skuCode: $skuCode
        from: $from
        to: $to
        meterData: $meterData
        concurrencyStamp: $concurrencyStamp
      }
    ) {
      message
      entity {
        id
        skuStatus
      }
    }
  }
`;
