import { gql } from 'graphql-request';

export const CREATE_TENANT = gql`
  mutation CreateTenant(
    $adminFirstName: String
    $adminLastName: String
    $email: String
    $name: String
    $domainName: String
    $managementLocation: String
  ) {
    createTenant(
      input: {
        adminFirstName: $adminFirstName
        adminLastName: $adminLastName
        email: $email
        name: $name
        domainName: $domainName
        managementLocation: $managementLocation
      }
    ) {
      message
      entity {
        concurrencyStamp
        id
      }
    }
  }
`;
