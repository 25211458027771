import { gql } from 'graphql-request';

export const UPDATE_TENANT = gql`
  mutation UpdateTenant($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      message
      entity {
        concurrencyStamp
      }
    }
  }
`;
