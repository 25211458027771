import { useIntl } from 'react-intl';

import { Box, Text } from '@blueprism/ui-core';

import { LicensingMeteringComponent } from '../../components';
import { useLicensingContext, useLicensingDrawerContext } from '../../context';
import { LicenseDrawerStates } from '../../constants';
import { formatLicenseDate } from '../../utils';

export function MeteringComponent() {
  const { formatMessage } = useIntl();
  const { openDrawer } = useLicensingDrawerContext();
  const { licenses } = useLicensingContext();

  const isMeteringAdded = licenses && licenses?.metering;

  const meterLicenses = isMeteringAdded && formatLicenseDate(licenses.metering);

  function handleOnClick() {
    openDrawer(LicenseDrawerStates.Metering);
  }

  return (
    <>
      <Box>
        <Text type="h5">{formatMessage({ id: 'account.licensing.metering.header' })}</Text>
      </Box>
      <Box>
        <LicensingMeteringComponent
          license={meterLicenses || formatMessage({ id: 'account.licensing.empty.metering.message' })}
          header={formatMessage({ id: 'account.licensing.metering.digitalWorkers.label' })}
          onButtonClick={handleOnClick}
          editButtonLabel={formatMessage({ id: 'account.licensing.metering.edit.button.label' })}
          buttonDisabled={!!isMeteringAdded}
        />
      </Box>
    </>
  );
}
