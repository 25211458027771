import type { License } from 'types/entities/';
import type { TenantMeteringCreationFormData } from '../types';

export const initialLicensingValues: License = {
  startDate: undefined,
  endDate: undefined,
};

export const initialMeteringValues: TenantMeteringCreationFormData = {
  startDate: undefined,
  endDate: undefined,
  value: '',
};
