import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { isEmpty } from '@blueprism/nextgen-platform-components';
import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';

import { GET_MANAGEMENT_REGIONS } from './api';

import type { GetManagementRegionsResponse } from './api';

export function useManagementRegionsApi(useErrorBoundary: false | ((error: unknown) => boolean)) {
  const { requestWithPrefix } = useApiClient();

  // TODO: Now this query is hardcoded with region value 'regions/us-east',
  // but in the future this should be query without the specified region prefix.
  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetManagementRegionList],
    queryFn: () => requestWithPrefix<GetManagementRegionsResponse>('regions/us-east', GET_MANAGEMENT_REGIONS),
    useErrorBoundary,
  });

  const managementRegionsOptions = useMemo(() => {
    const { managementRegions } = data || {};

    if (isEmpty(managementRegions)) return [];

    return managementRegions.map(({ name, displayName }) => ({
      id: name,
      value: displayName,
    }));
  }, [data]);

  return { managementRegionsOptions, isLoading };
}
