import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Form, FormLayout } from '@blueprism/nextgen-platform-components';
import { FormContent, TextFormField } from '@blueprism/ui-pattern-forms';

import { useAccountAdminDetailsApi } from './hooks';
import { AccountAdminStatusField } from './components';

import type { AccountDetailsParams } from 'types';
import type { AccountAdminDetailsFormData } from './types';

export function AccountAdminDetailsForm() {
  const { formatMessage } = useIntl();
  const { accountId } = useParams<AccountDetailsParams>();

  const { isLoading, tenantAdminDetails } = useAccountAdminDetailsApi(accountId);

  const initialValues: Omit<AccountAdminDetailsFormData, 'id'> = {
    firstName: tenantAdminDetails.firstName,
    lastName: tenantAdminDetails.lastName,
    email: tenantAdminDetails.email,
    status: tenantAdminDetails.status,
  };

  return (
    <Form enableReinitialize initialValues={initialValues} loading={isLoading} viewMode>
      <FormLayout>
        <FormContent>
          <AccountAdminStatusField status={tenantAdminDetails.status} userId={tenantAdminDetails.id} />
          <TextFormField disabled name="firstName" label={formatMessage({ id: 'account.details.firstName.label' })} />
          <TextFormField disabled name="lastName" label={formatMessage({ id: 'account.details.lastName.label' })} />
          <TextFormField disabled name="email" label={formatMessage({ id: 'account.details.email.label' })} />
        </FormContent>
      </FormLayout>
    </Form>
  );
}
