import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { GET_TENANT_NAME } from './api';

import type { TenantNameDataResponse, TenantNameVariablesRequest } from './api';

export function useTenantNameApi(id: string) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix, managementRegionsLoading } = useRegionContext();

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetTenantName, id, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<TenantNameDataResponse, TenantNameVariablesRequest>(managementRegionPrefix, GET_TENANT_NAME, {
        id,
      }),
    enabled: !!id && !!managementRegionPrefix,
    useErrorBoundary: false,
  });

  const tenantName = data?.tenant?.name || '';
  const isLoading = isFetching || managementRegionsLoading;

  return { isLoading, tenantName };
}
