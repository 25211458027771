import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { DEFAULT_DOMAIN_NAME_MAX_SUBDOMAINS } from '../../constants';

import { GET_RUNNING_DOMAIN_QUERY } from './api';

import type { GetRunningDomainResponse } from './api';

export function useRunningDomainApi() {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix } = useRegionContext();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetRunningDomainDetails, managementRegionPrefix],
    queryFn: () => requestWithPrefix<GetRunningDomainResponse>(managementRegionPrefix, GET_RUNNING_DOMAIN_QUERY),
    enabled: !!managementRegionPrefix,
  });

  return {
    domain: data?.runningDomain?.domain || '',
    maxTenantSubdomainLevel: data?.runningDomain.maxTenantSubdomainLevel || DEFAULT_DOMAIN_NAME_MAX_SUBDOMAINS,
    isLoading,
  };
}
