import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Stack, Row, Text, useTheme } from '@blueprism/ui-core';
import { Cross, Tick } from '@blueprism/ui-icons';
import { ScrollToTop } from '@blueprism/nextgen-platform-components';
import { Table, useTable } from '@blueprism/nextgen-platform-table';
import type { TableColumns } from '@blueprism/nextgen-platform-table';

import { useTenantDetailsApi } from '../../hooks';

import type { AccountDetailsParams } from 'types';
import type { OnboardingStatus } from './types';

export function Onboarding() {
  const { accountId: id } = useParams<AccountDetailsParams>();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const { onboarding, isLoading } = useTenantDetailsApi(id);

  const listData = Object.entries(onboarding).map<OnboardingStatus>(
    ([key, value]) =>
      ({
        id: key,
        status: { [key]: value },
      }) as OnboardingStatus,
  );

  const tableColumns: TableColumns<OnboardingStatus> = {
    status: {
      order: 1,
      header: formatMessage({ id: 'account.table.status' }),
      tableCell: (status = {}) => {
        const [[key, value]] = Object.entries(status);

        return (
          <Row aria-disabled align="center" gap="small">
            {/* @ts-ignore COMPLIB-2110 The theme is not available from the outside of complib */}
            {value ? <Tick size={24} color={theme.color.action} /> : <Cross size={24} color={theme.color.disabled} />}
            <Text themeColor={value ? 'text' : 'disabled'}>{formatMessage({ id: `account.onboarding.${key}` })}</Text>
          </Row>
        );
      },
      width: '20%',
    },
  };

  const { ...tableProps } = useTable({
    tableConfig: {
      columns: tableColumns,
    },
  });

  return (
    <Row width="100%" justify="between">
      <ScrollToTop />
      <Stack gap="medium" width="40rem">
        <Text type="h5">{formatMessage({ id: 'account.onboarding.title.signUpProgress' })}</Text>
        <Table rowId="id" isLoading={isLoading} data={listData} {...tableProps} />
      </Stack>
    </Row>
  );
}
