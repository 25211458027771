import { useRef } from 'react';

import { Form, DiscardChangesModalProvider } from '@blueprism/nextgen-platform-components';

import { AddLicenseDrawer } from '../../AddLicenseDrawer';
import { initialMeteringValues } from '../../../constants';
import { useMeteringCreationApi } from '../../../hooks';

import { AddMeteringFormBody } from './AddMeteringFormBody';

import type { FormikProps } from 'formik';
import type { TenantMeteringCreationFormData, HeaderLabel } from '../../../types';

export function AddMeteringDrawerForm({ header }: HeaderLabel) {
  const formikRef = useRef<FormikProps<TenantMeteringCreationFormData>>(null);

  const { handleSubmit } = useMeteringCreationApi();

  return (
    <DiscardChangesModalProvider>
      <AddLicenseDrawer header={header} formikRef={formikRef}>
        <Form initialValues={initialMeteringValues} onSubmit={handleSubmit} ref={formikRef}>
          <AddMeteringFormBody />
        </Form>
      </AddLicenseDrawer>
    </DiscardChangesModalProvider>
  );
}
