import { useFormatDate } from '@blueprism/nextgen-platform-components';

import type { License } from 'types/entities/';

export function formatLicenseDate({ startDate, endDate }: License<string>) {
  const { formatDate } = useFormatDate('yyyy, MMMM d');

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}

export function formatDateToString(date: Date | undefined) {
  const { formatDate } = useFormatDate('yyyy-MM-dd HH:mm:ss');

  return formatDate(date?.setHours(0, 0, 0));
}
