import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useResendInvitationApi } from '../useResendInvitationApi';
import { AccountAdminStatus } from '../../../../constants';

import type { AccountDetailsParams } from 'types';

export function useAccountAdminStatus(status: AccountAdminStatus, userId: string) {
  const { accountId } = useParams<AccountDetailsParams>();
  const { formatMessage } = useIntl();

  const { handleResendInvitation, isLoading } = useResendInvitationApi(userId, accountId);

  const showStatusButton = [
    AccountAdminStatus.INVITED,
    AccountAdminStatus.INVITATION_REQUESTED,
    AccountAdminStatus.INVITATION_RESENT,
    AccountAdminStatus.TENANT_ADMIN_CREATED,
    AccountAdminStatus.TENANT_ADMIN_CREATION_REQUESTED,
    AccountAdminStatus.INVITE_FAILED,
  ].includes(status);

  const defaultUserStatus = {
    showStatusButton,
    isLoading,
    handleStatusAction: () => {},
    userStatus: '',
    buttonLabel: '',
  };

  switch (status) {
    case AccountAdminStatus.ACTIVE:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.ACTIVE' }),
      };
    case AccountAdminStatus.ACTIVATION_REQUESTED:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.ACTIVATION_REQUESTED' }),
      };
    case AccountAdminStatus.INVITED:
    case AccountAdminStatus.INVITATION_REQUESTED:
    case AccountAdminStatus.INVITATION_RESENT:
    case AccountAdminStatus.TENANT_ADMIN_CREATED:
    case AccountAdminStatus.TENANT_ADMIN_CREATION_REQUESTED:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.INVITATION_SENT' }),
        buttonLabel: formatMessage({ id: 'account.details.status.button.resendInvitation' }),
        handleStatusAction: handleResendInvitation,
      };
    case AccountAdminStatus.INVITE_FAILED:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.INVITE_FAILED' }),
        buttonLabel: formatMessage({ id: 'account.details.status.button.resendInvitation' }),
        handleStatusAction: handleResendInvitation,
      };
    case AccountAdminStatus.DEACTIVATED:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.DEACTIVATED' }),
      };
    case AccountAdminStatus.DEACTIVATION_REQUESTED:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.DEACTIVATION_REQUESTED' }),
      };
    case AccountAdminStatus.UNKNOWN:
      return { ...defaultUserStatus, userStatus: formatMessage({ id: 'account.details.status.label.UNKNOWN' }) };
    default:
      return defaultUserStatus;
  }
}
