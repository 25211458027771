import { useHistory } from 'react-router-dom';

import { Routes } from 'routes';
import { useRegionContext } from 'services/region';

export function useAccountMultiRegionRouting() {
  const { selectedManagementRegion } = useRegionContext();
  const { push } = useHistory();

  function goToAccountDetails(accountId?: string) {
    push(Routes.getAccountDetailsUrl(selectedManagementRegion?.id, accountId));
  }

  function goToEditAccountDetails(accountId?: string) {
    push(Routes.getEditAccountDetailsUrl(selectedManagementRegion?.id, accountId));
  }

  function goToproductSkusListPage(accountId: string, productId: string) {
    push(Routes.getProductConfigurationUrl(selectedManagementRegion?.id, accountId, productId));
  }

  return {
    goToAccountDetails,
    goToEditAccountDetails,
    goToproductSkusListPage,
  };
}
