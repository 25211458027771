import { gql } from 'graphql-request';

export const ADD_TENANT_LICENSES = gql`
  mutation AddTenantLicenses($tenantId: Uuid!, $productId: String!, $licenses: [TenantLicenseDetailsInput!]!) {
    addTenantLicenses(input: { tenantId: $tenantId, productId: $productId, licenses: $licenses }) {
      message
      entity {
        licenses {
          displayName
          endDate
          environmentId
          name
          productId
          startDate
          tenantId
          version
        }
      }
    }
  }
`;
