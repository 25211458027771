export enum TenantType {
  Standard = 'false',
  Trial = 'true',
}

export enum TenantStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  DEACTIVATED = 'DEACTIVATED',
  PERMANENTLY_DEACTIVATED = 'PERMANENTLY_DEACTIVATED',
}

interface TenantChanger {
  userEmail?: string;
  userFirstName?: string;
  userId?: string;
  userLastName?: string;
}

export interface Tenant {
  cloudRegionName?: string;
  concurrencyStamp: string;
  createdBy: TenantChanger;
  dateCreated: string;
  dateUpdated?: string;
  domainName?: string;
  id: string;
  isAdminUserCreated: boolean;
  isAuthenticationConfigured?: boolean;
  isDomainConfigured?: boolean;
  isLicenseConfigured?: boolean;
  isMeterConfigured?: boolean;
  managementLocation?: string;
  name?: string;
  status: `${TenantStatus}`;
  termsAgreedDate: string;
  updatedBy?: TenantChanger;
}
