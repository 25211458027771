import styled from 'styled-components';

export const StyledText = styled.p`
  color: ${({ theme }) => theme.color.text};
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
`;

export const StyledListItem = styled.li`
  word-wrap: break-word;
  list-style-type: disc;
  list-style-position: inside;
`;
