import { useContext } from 'react';

import { CurrentSkuContext } from '../../CurrentSkuProvider';

export function useCurrentSkuContext() {
  const currentSkuData = useContext(CurrentSkuContext);

  if (currentSkuData === undefined) {
    throw new Error('useCurrentSkuContext must be within CurrentSkuProvider scope');
  }

  return currentSkuData;
}
