import { useRef } from 'react';

import { Form, DiscardChangesModalProvider } from '@blueprism/nextgen-platform-components';

import { AddLicenseDrawer } from '../../AddLicenseDrawer';
import { initialLicensingValues } from '../../../constants';
import { useLicensingCreationApi } from '../../../hooks';

import { AddLicensingFormBody } from './AddLicensingFormBody';

import type { License } from 'types/entities/';
import type { FormikProps } from 'formik';
import type { HeaderLabel } from '../../../types';

export function AddLicensingDrawerForm({ header }: HeaderLabel) {
  const formikRef = useRef<FormikProps<License>>(null);

  const { handleSubmit } = useLicensingCreationApi();

  return (
    <DiscardChangesModalProvider>
      <AddLicenseDrawer header={header} formikRef={formikRef}>
        <Form initialValues={initialLicensingValues} onSubmit={handleSubmit} ref={formikRef}>
          <AddLicensingFormBody />
        </Form>
      </AddLicenseDrawer>
    </DiscardChangesModalProvider>
  );
}
