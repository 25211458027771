import { gql } from 'graphql-request';

export const ADMIN_CREATE_METER = gql`
  mutation AdminCreateMeter(
    $tenantId: Uuid!
    $productId: String!
    $name: String!
    $limit: Int!
    $type: MeterType!
    $version: String!
    $displayName: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $operationLevel: MeterOperationLevel!
  ) {
    adminCreateMeter(
      input: {
        tenantId: $tenantId
        productId: $productId
        name: $name
        limit: $limit
        type: $type
        version: $version
        displayName: $displayName
        startDate: $startDate
        endDate: $endDate
        operationLevel: $operationLevel
      }
    ) {
      message
      entity {
        tenantId
        productId
        name
        type
        limit
        version
        displayName
        startDate
        endDate
      }
    }
  }
`;
