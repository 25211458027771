import { gql } from 'graphql-request';

export const GET_TENANTS = gql`
  query GetTenants(
    $order: [TenantSortInput!]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: TenantFilterInput
  ) {
    tenants(order: $order, first: $first, last: $last, after: $after, before: $before, where: $where) {
      totalCount
      edges {
        node {
          id
          name
          status
          dateCreated
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;
