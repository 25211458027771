import styled from 'styled-components';

import { Box } from '@blueprism/ui-core';

import type { ThemedStyledProps, DefaultTheme } from 'styled-components';

interface StyledContainerProps {
  licensesDisabled?: boolean;
  accountDisabled?: boolean;
}

export const StyledContainer = styled(Box)<ThemedStyledProps<StyledContainerProps, DefaultTheme>>`
  ${({ licensesDisabled = false, accountDisabled = false, theme }) => `
    #tab-add-account-page-tabs-1 { ${licensesDisabled ? 'pointer-events: none;' : ''} }
        #tab-add-account-page-tabs-1 span {
        color: ${licensesDisabled ? theme.color.disabled : ''};
    }
        #tab-add-account-page-tabs-1 svg path {
        fill: ${licensesDisabled ? theme.color.disabled : ''}
    }

    #tab-add-account-page-tabs-0 { ${accountDisabled ? 'pointer-events: none;' : ''} }
        #tab-add-account-page-tabs-0 span {
        color: ${accountDisabled ? theme.color.disabled : ''};
    }
        #tab-add-account-page-tabs-0 svg path {
        fill: ${accountDisabled ? theme.color.disabled : ''}
    }
  `}
`;
