import { useIntl } from 'react-intl';

import { Stack, Text, Row, Button } from '@blueprism/ui-core';
import { Pencil } from '@blueprism/ui-icons';

import { StyledText } from './BaseComponent.styled';

import type { BaseComponentProps } from './types';

export function BaseComponent({
  header,
  license,
  description,
  onButtonClick,
  editButtonLabel,
  buttonDisabled,
}: BaseComponentProps) {
  const { formatMessage } = useIntl();

  return (
    <Stack gap="large">
      <Row justify="between" align="center" width="50rem">
        <StyledText>{header}</StyledText>
        <Text>{license || formatMessage({ id: 'account.licensing.empty.license.message' })}</Text>
        <Button icon={<Pencil />} disabled={buttonDisabled} onClick={onButtonClick}>
          {editButtonLabel || formatMessage({ id: 'account.licensing.productLicensing.edit.button.label' })}
        </Button>
      </Row>
      {description && <Text>{description}</Text>}
    </Stack>
  );
}
