import { useContext } from 'react';

import { ProductActivationDrawerContext } from '../../ProductActivationDrawerProvider';

export function useProductActivationDrawerContext() {
  const productActivationDrawerData = useContext(ProductActivationDrawerContext);

  if (productActivationDrawerData === undefined) {
    throw new Error('useProductActivationDrawerContext must be within ProductActivationProvider scope');
  }

  return productActivationDrawerData;
}
