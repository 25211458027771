import { useContext } from 'react';

import { LicensingDrawerContext } from '../LicensingDrawerProvider';

export function useLicensingDrawerContext() {
  const licensingDrawerData = useContext(LicensingDrawerContext);

  if (licensingDrawerData === undefined) {
    throw new Error('useLicensingDrawerContext must be within LicensingDrawerProvider scope');
  }

  return licensingDrawerData;
}
