import { useCallback } from 'react';

import { useIntl } from 'react-intl';

import { useDiscardChangesModal } from '@blueprism/nextgen-platform-components';
import { Drawer } from '@blueprism/ui-pattern-drawer';
import type { PropsWithChildren } from '@blueprism/nextgen-platform-components';

import { useLicensingDrawerContext } from '../../context';

import type { AddLicenseDrawerProps } from './types';

export function AddLicenseDrawer<T>({ children, header, formikRef }: PropsWithChildren<AddLicenseDrawerProps<T>>) {
  const { formatMessage } = useIntl();

  const { waitForAnswer } = useDiscardChangesModal();
  const { drawerState, closeDrawer } = useLicensingDrawerContext();

  const onResetClick = useCallback(() => {
    formikRef.current?.resetForm();
  }, [formikRef.current?.resetForm]);

  async function handleWaitForAnswer() {
    if (formikRef.current?.dirty) {
      const confirmationDecision = await waitForAnswer({
        titleText: formatMessage({ id: 'form.modal.title.discardChanges' }),
        bodyText: formatMessage({ id: 'form.modal.question.leavePage' }),
        cancelLabel: formatMessage({ id: 'form.modal.button.stayOnPage' }),
        acceptLabel: formatMessage({ id: 'form.modal.button.discardAndLeave' }),
      });

      return confirmationDecision;
    }

    return true;
  }

  async function handleClose() {
    const confirmationDecision = await handleWaitForAnswer();

    if (confirmationDecision) {
      onResetClick();
      closeDrawer();
    }
  }

  return (
    <Drawer
      toolbarAriaLabel="add-license-drawer"
      title={formatMessage({ id: `${header}` })}
      closeButtonLabel={formatMessage({ id: 'buttons.close' })}
      anchor="right"
      open={!!drawerState}
      onClose={handleClose}
      width={525}
    >
      {children}
    </Drawer>
  );
}
