import { gql } from 'graphql-request';

export const GET_PRODUCTS = gql`
  query GetProducts($tenantId: Uuid!) {
    products(request: { tenantId: $tenantId }) {
      edges {
        node {
          id
          name
          isActivatedInTenant
        }
      }
    }
  }
`;
