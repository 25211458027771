import { createContext, useState } from 'react';

import type { PropsWithChildren } from '@blueprism/nextgen-platform-components';

import type { LicensingContextState, LicensingState } from './types';

export const LicensingContext = createContext<LicensingContextState>({
  licenses: undefined,
  setLicenses: () => {},
  currentTenantId: undefined,
  setCurrentTenantId: () => {},
  currentTenantRegion: undefined,
  setCurrentTenantRegion: () => {},
});

export function LicensingProvider({ children }: PropsWithChildren) {
  const [licenses, setLicenses] = useState<LicensingState | undefined>(undefined);
  const [currentTenantId, setCurrentTenantId] = useState<string | undefined>(undefined);
  const [currentTenantRegion, setCurrentTenantRegion] = useState<string | undefined>(undefined);

  return (
    <LicensingContext.Provider
      value={{
        licenses,
        setLicenses,
        currentTenantId,
        setCurrentTenantId,
        currentTenantRegion,
        setCurrentTenantRegion,
      }}
    >
      {children}
    </LicensingContext.Provider>
  );
}
