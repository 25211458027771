import { StatusLabel } from '@blueprism/ui-core';
import type { FormatMessage } from '@blueprism/nextgen-platform-components';

import { SkuStatus } from 'types/entities/Sku';

import type { ProductSku } from './hooks/useActivatedSkusByProduct/api';

export function formatSkuStatus(status: `${SkuStatus}` | undefined, formatMessage: FormatMessage) {
  const message = status ? formatMessage({ id: `account.productConfiguration.table.status.${status}` }) : '';

  if (status === SkuStatus.ACTIVE) {
    return <StatusLabel variant="success">{message}</StatusLabel>;
  }

  return <StatusLabel variant="warning">{message}</StatusLabel>;
}

export function getProductLicensesList(listData: ProductSku[] = []) {
  const licensesList = listData.reduce((acc, { licenses }) => {
    const licenseNames: string[] = [];
    licenses?.forEach(({ displayName }) => licenseNames.push(displayName));

    return [...acc, ...licenseNames];
  }, [] as string[]);

  return [...new Set(licensesList)];
}
