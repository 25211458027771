import { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';
import { useToast, ToastType } from '@blueprism/ui-pattern-toasts';

import { Routes } from 'routes/constants';
import { getManagementRegionPrefix } from 'services/region';

import { useLicensingContext, useLicensingDrawerContext } from '../../context';
import { formatDateToString } from '../../utils/formatLicenseDate';
import { OperationLevel } from '../../constants';

import { ADMIN_CREATE_METER } from './api';

import type { FormikHelpers } from 'formik';
import type { AdminCreateMeterVariables, AdminCreateMeterResponse } from './api';
import type { TenantMeteringCreationFormData } from '../../types';

export function useMeteringCreationApi() {
  const { requestWithPrefix } = useApiClient();
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const { triggerToast } = useToast();

  const [isLicenseAdded, setIsLicenseAdded] = useState(false);
  const { closeDrawer } = useLicensingDrawerContext();
  const { setLicenses, licenses, currentTenantId = '', currentTenantRegion = '' } = useLicensingContext();

  const productId = formatMessage({ id: 'account.licensing.metering.digitalWorkers.label' });

  function handleRedirectToLicensing(tenantId?: string) {
    push(Routes.getLicensingUrl(tenantId));
  }

  function handleRedirectToGrid() {
    push(Routes.Account);
  }

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (variables: AdminCreateMeterVariables) => {
      const managementRegionPrefix = getManagementRegionPrefix(currentTenantRegion);

      return requestWithPrefix<AdminCreateMeterResponse>(managementRegionPrefix, ADMIN_CREATE_METER, variables);
    },
    meta: {
      badRequestToastContent: {
        title: formatMessage({ id: 'account.notification.meterCreationFailed' }),
        description: formatMessage({ id: 'account.notification.meterCreationFailed.message' }),
      },
    },
  });

  async function handleSubmit(
    { startDate, endDate, value = '' }: Partial<TenantMeteringCreationFormData>,
    actions: FormikHelpers<TenantMeteringCreationFormData>,
  ) {
    try {
      const response = await mutateAsync({
        tenantId: currentTenantId,
        productId,
        name: 'blueprism.ia.rpasuite.activeconcurrentsessions',
        limit: +value,
        type: 'FIXED_CONCURRENT_LIMIT',
        version: '1.0',
        displayName: 'displayMeter',
        startDate: formatDateToString(startDate),
        endDate: formatDateToString(endDate),
        operationLevel: OperationLevel.Environment,
      });

      const accountData = response.adminCreateMeter;

      triggerToast({
        type: ToastType.SUCCESS,
        title: formatMessage({ id: 'account.notification.meterCreated' }),
        description: formatMessage({ id: 'account.notification.meterCreated.message' }, { productId }),
      });

      setLicenses({ ...licenses, metering: accountData?.entity });

      setIsLicenseAdded(true);
      actions.resetForm();
      closeDrawer();
    } catch (error) {
      handleRedirectToLicensing(currentTenantId);
    }
  }

  return { isLoading, handleSubmit, handleRedirectToGrid, isLicenseAdded };
}
