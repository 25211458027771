import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { Box, ToolbarButton } from '@blueprism/ui-core';
import { Pencil } from '@blueprism/ui-icons';

import { useAccountMultiRegionRouting } from 'hooks';

import type { AccountDetailsParams } from 'types';

export function AccountDetailsControlGroup() {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const { goToEditAccountDetails } = useAccountMultiRegionRouting();
  const { accountId: id } = useParams<AccountDetailsParams>();

  function handleEditButtonClick() {
    goToEditAccountDetails(id);
  }
  const showEditButton = !pathname.endsWith('/edit');

  return showEditButton ? (
    <Box>
      <ToolbarButton
        label={formatMessage({ id: 'buttons.edit' })}
        onClick={handleEditButtonClick}
        icon={<Pencil size={16} />}
        disabled={!id}
      />
    </Box>
  ) : null;
}
