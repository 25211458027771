import { useIntl } from 'react-intl';

import { Box, Text } from '@blueprism/ui-core';

import { LicensingMeteringComponent } from '../../components';
import { useLicensingContext, useLicensingDrawerContext } from '../../context';
import { LicenseDrawerStates } from '../../constants';
import { formatLicenseDate } from '../../utils';

export function ProductLicensingComponent() {
  const { formatMessage } = useIntl();
  const { openDrawer } = useLicensingDrawerContext();
  const { licenses } = useLicensingContext();

  const isLicensingAdded = licenses && licenses?.licensing;

  function handleOnClick() {
    openDrawer(LicenseDrawerStates.Product);
  }

  return (
    <>
      <Box>
        <Text type="h5">{formatMessage({ id: 'account.licensing.productLicensing.header' })}</Text>
      </Box>
      <LicensingMeteringComponent
        license={isLicensingAdded && formatLicenseDate(licenses.licensing)}
        header={formatMessage({ id: 'account.licensing.productLicensing.hybridHostingRPA.label' })}
        description={formatMessage({ id: 'account.licensing.accountLicensing.description' })}
        onButtonClick={handleOnClick}
        buttonDisabled={!!isLicensingAdded}
      />
    </>
  );
}
