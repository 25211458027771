import * as Yup from 'yup';

import type { CreateValidation } from '@blueprism/nextgen-platform-components';

import type { LanguageKeys } from 'translations';
import type { PrimitiveType } from 'intl-messageformat';

export const createValidation: CreateValidation<any> = (formatMessage) => {
  const t = (text: LanguageKeys, values?: Record<string, PrimitiveType>) => formatMessage({ id: text }, values);

  return {
    valueSchema: Yup.string()
      .test('no-starts-with-zero', t('account.licensing.drawer.form.value.error'), (value) => !value?.startsWith('0'))
      .matches(/^[0-9]+$/, t('account.licensing.drawer.form.value.error'))
      .required(t('account.licensing.drawer.form.value.required.error')),
  };
};
