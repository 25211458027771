import { useRef } from 'react';

import { DiscardChangesModalProvider, LeaveOrReloadPrompt } from '@blueprism/nextgen-platform-components';
import { Stack } from '@blueprism/ui-core';

import {
  ProductLicensingComponent,
  MeteringComponent,
  AddMeteringDrawerForm,
  AddLicensingDrawerForm,
} from './components';
import { LicenseDrawerStates } from './constants';
import { useLicensingContext, useLicensingDrawerContext } from './context';

export function Licensing() {
  const { drawerState } = useLicensingDrawerContext();
  const { licenses } = useLicensingContext();
  const isLicenseAddedRef = useRef(false);

  const isLicensesAndMeterAdded = Boolean(licenses && licenses?.licensing && licenses?.metering);

  function changeLicense() {
    isLicenseAddedRef.current = true;
  }

  return (
    <>
      <Stack gap="large">
        <ProductLicensingComponent />
        <MeteringComponent />
      </Stack>
      {drawerState === LicenseDrawerStates.Product && (
        <AddLicensingDrawerForm header="account.licensing.productLicensing.hybridHostingRPA.label" />
      )}
      {drawerState === LicenseDrawerStates.Metering && (
        <AddMeteringDrawerForm header="account.licensing.metering.digitalWorkers.label" />
      )}
      {!isLicensesAndMeterAdded && (
        <DiscardChangesModalProvider>
          <LeaveOrReloadPrompt
            onModalSuccess={changeLicense}
            isSubmitting={false}
            isNavigationBlocked={!isLicenseAddedRef.current}
          />
        </DiscardChangesModalProvider>
      )}
    </>
  );
}
