import { useContext } from 'react';

import { RegionContext } from '../../RegionContext';

export const useRegionContext = () => {
  const context = useContext(RegionContext);

  if (context === undefined) {
    throw new Error('useRegionContext used outside RegionContextProvider.');
  }

  return context;
};
