import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { GET_ACTIVATED_SKUS_BY_PRODUCT } from './api';

import type { GetActivatedSkusByProductResponse, UseActivatedSkusByProduct, RequestVariables } from './api';

export function useActivatedSkusByProduct({ createPagingOptions, variables }: UseActivatedSkusByProduct) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix, managementRegionsLoading } = useRegionContext();

  const { tenantId, productId } = variables.request;

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetActivatedSkusByProduct, variables, tenantId, productId, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<GetActivatedSkusByProductResponse, RequestVariables>(
        managementRegionPrefix,
        GET_ACTIVATED_SKUS_BY_PRODUCT,
        variables,
      ),
    enabled: !!managementRegionPrefix && !!tenantId && !!productId,
  });

  const listData = data?.activatedSkusByProductForTenant?.edges.map(({ node }) => node);
  const pageInfo = data?.activatedSkusByProductForTenant?.pageInfo;
  const totalCount = data?.activatedSkusByProductForTenant?.totalCount;

  const pagingOptions = createPagingOptions(pageInfo, totalCount);

  const isLoading = isFetching || managementRegionsLoading;

  return { listData, isLoading, pagingOptions };
}
