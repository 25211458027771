export enum QueryKeys {
  GetManagementRegionList = 'get-management-regions',
  GetTenantList = 'get-tenants',
  GetTenantName = 'get-tenant-name',
  GetTenantDetails = 'get-tenant-details',
  GetAccountAdminDetails = 'get-account-admin-details',
  GetRunningDomainDetails = 'get-running-domain',
  GetDomainValidation = 'get-domain-validation',
  GetTenantProducts = 'get-tenant-products',
  GetProductSkus = 'get-product-skus',
  GetProductCatalogueTenant = 'get-product-catalogue-tenant',
  GetActivatedSkusByProduct = 'get-activated-skus-by-product',
}
