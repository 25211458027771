import { Row, Placeholder } from '@blueprism/ui-core';

export function StatusFieldPlaceholder() {
  return (
    <Row gap="base">
      <Placeholder height="2.5rem" width="10rem" />
      <Placeholder height="2.5rem" width="10rem" />
    </Row>
  );
}
