import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { GET_PRODUCT_CATALOGUE_TENANT } from './api';

import type { GetProductCatalogueTenantVariables, GetProductCatalogueTenantResponse } from './api';

export function useProductCatalogueTenant(tenantId: string) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix } = useRegionContext();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetProductCatalogueTenant, tenantId, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<GetProductCatalogueTenantResponse, GetProductCatalogueTenantVariables>(
        managementRegionPrefix,
        GET_PRODUCT_CATALOGUE_TENANT,
        {
          tenantId,
        },
      ),
    enabled: !!tenantId && !!managementRegionPrefix,
  });

  const concurrencyStamp = data?.productCatalogueTenant?.concurrencyStamp;

  return { isLoading, concurrencyStamp };
}
