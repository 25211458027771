import { gql } from 'graphql-request';

export const GET_DOMAIN_NAME_VALIDATION = gql`
  query GetTenantDomainValidation($domainName: String) {
    validateTenantDomainName(domainName: $domainName) {
      validDomainName
      subDomain
      domain
      domainName
      validatedDate
    }
  }
`;
