import { useMemo } from 'react';

import { Switch, Route, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { RouterTabs } from '@blueprism/nextgen-platform-components';
import { useBreadcrumbs } from '@blueprism/nextgen-shared-hooks';
import { PageLayout } from '@blueprism/ui-layouts';
import { Stack, Text, Breadcrumbs, Link } from '@blueprism/ui-core';
import { ApplicationServer, Page } from '@blueprism/ui-icons';

import { Routes } from 'routes/constants';

import { Licensing, LicensingDrawerProvider } from './Licensing';
import { AccountCreationPage } from './AccountDetails';
import { StyledContainer } from './AccountCreation.styled';
import { useLicensingContext } from './Licensing/context';

export function AccountCreation() {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { currentTenantId } = useLicensingContext();

  const isAddAccountLocation = useMemo(() => location.pathname === Routes.AccountAdd, [location.pathname]);
  const isLicensingLocation = useMemo(
    () => location.pathname === Routes.getLicensingUrl(currentTenantId),
    [location.pathname],
  );

  const breadCrumbLinks = [
    { href: Routes.Account, title: formatMessage({ id: 'navigation.accountList.label' }) },
    { title: formatMessage({ id: 'navigation.createNewAccount.label' }) },
  ];

  const { breadcrumbs } = useBreadcrumbs(breadCrumbLinks);

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs ariaLabel={formatMessage({ id: 'navigation.breadcrumbs.accountCreation.ariaLabel' })}>
          {breadcrumbs.map(({ title, navigable, ...linkProps }) =>
            navigable ? (
              <Link {...linkProps} key={title}>
                {title}
              </Link>
            ) : (
              <Text key={title}>{title}</Text>
            ),
          )}
        </Breadcrumbs>
      }
      pageTitle={<Text type="h3">{formatMessage({ id: 'account.create.form.title' })}</Text>}
      pageContent={
        <Stack gap="medium">
          <StyledContainer licensesDisabled={isAddAccountLocation} accountDisabled={isLicensingLocation}>
            <RouterTabs
              name="add-account-page-tabs"
              tabsInfo={[
                {
                  label: formatMessage({ id: 'account.details.btn.accountDetails' }),
                  icon: <Page />,
                  route: Routes.AccountAdd,
                },
                {
                  label: formatMessage({ id: 'account.details.btn.licensing' }),
                  icon: <ApplicationServer />,
                  route: Routes.getLicensingUrl(currentTenantId),
                },
              ]}
            />
          </StyledContainer>

          <Switch>
            <Route exact path={Routes.getLicensingUrl(currentTenantId)}>
              <LicensingDrawerProvider>
                <Licensing />
              </LicensingDrawerProvider>
            </Route>
            <Route path={Routes.AccountAdd}>
              <AccountCreationPage />
            </Route>
          </Switch>
        </Stack>
      }
    />
  );
}
