import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { FormContent, BasicFormLayout } from '@blueprism/ui-pattern-forms';
import type { ComboBoxFieldOption } from '@blueprism/nextgen-platform-components';
import { CancelButton, ComboBoxField, isEmpty, SubmitButton } from '@blueprism/nextgen-platform-components';
import { List, Stack } from '@blueprism/ui-core';

import { useProductActivationDrawerContext, useCurrentSkuContext } from '../../context';
import { useSkusApi } from '../../hooks';
import { getLicenseName } from '../../utils';

import { StyledListItem, StyledText } from './ProductActivationFormContent.styled';
import { FromToPicker, MeterTextField } from './components';
import { createValidation } from './validationSchema';

import type { ActivateProductFormData } from '../../types';

export function ProductActivationFormContent() {
  const { formatMessage } = useIntl();
  const { values, resetForm, setFieldValue, isSubmitting } = useFormikContext<ActivateProductFormData>();
  const { meters, licenses, setCurrentSku } = useCurrentSkuContext();

  const { skuSchema } = createValidation(formatMessage);

  const { drawerState, closeDrawer } = useProductActivationDrawerContext();

  const { skus, isLoading } = useSkusApi(drawerState?.id ?? '');

  const isSkuSelected = values?.sku?.id;
  const isCurrentSkusLicensesExists = isSkuSelected && licenses.length > 0;
  const isCurrentSkusMetersExists = isSkuSelected && meters.length > 0;

  const skusOptions = skus.map((sku) => ({ value: sku.name, id: sku.code }));

  function handleSkuChange(skusOption: ComboBoxFieldOption | undefined) {
    if (isEmpty(skusOption)) return;

    resetForm();
    setFieldValue('sku', skusOption);

    const selectedSku = skus.find((sku) => sku.code === skusOption.id) || null;
    setCurrentSku(selectedSku);
  }

  return (
    <BasicFormLayout>
      <FormContent>
        <ComboBoxField
          name="sku"
          label={formatMessage({ id: 'account.products.activateProducts.drawer.form.addSku.label' })}
          helperText={formatMessage({ id: 'account.products.activateProducts.drawer.form.addSku.helperText' })}
          options={skusOptions}
          loading={isLoading}
          disabled={isSubmitting}
          required
          validate={skuSchema}
          onChange={handleSkuChange}
        />
        {isCurrentSkusLicensesExists && (
          <Stack gap="small">
            <StyledText>
              {formatMessage({ id: 'account.products.activateProducts.drawer.form.licenses.label' })}
            </StyledText>
            <List element="ul">
              {licenses.map(({ displayName }) => (
                <StyledListItem key={displayName}>{getLicenseName(displayName, formatMessage)}</StyledListItem>
              ))}
            </List>
          </Stack>
        )}
        {isSkuSelected && <FromToPicker />}
        {isCurrentSkusMetersExists && meters.map((meter) => <MeterTextField key={meter.displayName} {...meter} />)}
      </FormContent>

      <SubmitButton label={formatMessage({ id: 'buttons.save' })} />
      <CancelButton showDiscardChangesModal onCancel={closeDrawer} label={formatMessage({ id: 'buttons.cancel' })} />
    </BasicFormLayout>
  );
}
