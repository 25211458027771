import { createContext, useState } from 'react';

import { DiscardChangesModalProvider, type PropsWithChildren } from '@blueprism/nextgen-platform-components';

import { CurrentSkuProvider } from '../CurrentSkuProvider';

import type { ProductProps } from 'types/entities';
import type { ProductActivationDrawerContextState } from './types';
import type { ProductActivationFormDrawerProps } from '../../types';

export const ProductActivationDrawerContext = createContext<
  ProductActivationDrawerContextState & ProductActivationFormDrawerProps
>({
  drawerState: null,
  openDrawer: () => {},
  closeDrawer: () => {},
  productConfigurationPage: undefined,
});

export function ProductActivationDrawerProvider({
  children,
  productConfigurationPage,
}: PropsWithChildren<ProductActivationFormDrawerProps>) {
  const [drawerState, setDrawerState] = useState<ProductProps | null>(null);

  function openDrawer(drawer: ProductProps | null) {
    setDrawerState(drawer);
  }

  function closeDrawer() {
    setDrawerState(null);
  }

  return (
    <ProductActivationDrawerContext.Provider value={{ drawerState, openDrawer, closeDrawer, productConfigurationPage }}>
      <DiscardChangesModalProvider>
        <CurrentSkuProvider>{children}</CurrentSkuProvider>
      </DiscardChangesModalProvider>
    </ProductActivationDrawerContext.Provider>
  );
}
