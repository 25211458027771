import { useContext } from 'react';

import { AuthContext } from '../../AuthContext';

import type { AuthStateContext } from 'services/auth/types';

export function useAuth(): AuthStateContext {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be within AuthProvider scope');
  }

  return context;
}
