import { useContext } from 'react';

import { ConfigContext } from '../../ConfigContext';

import type { ConfigStateContext } from 'services/config/types';

export function useConfig(): ConfigStateContext {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('useConfig must be within ConfigProvider scope');
  }

  return context;
}
