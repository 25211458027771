import { IndicatorStates } from '@blueprism/ui-core';

import type { UseMutationResult } from '@tanstack/react-query';
import type { ValidateDomainNameResponse, ValidateDomainNameVariables } from '../../api';

export function parseDomainIndicatorState({
  data,
  isIdle,
  isError,
  isLoading,
}: Pick<
  UseMutationResult<ValidateDomainNameResponse, ValidateDomainNameVariables>,
  'data' | 'isError' | 'isIdle' | 'isLoading'
>) {
  return function validateDomain(name: string) {
    const { subDomain, validDomainName } = data?.validateTenantDomainName || {};

    if (isLoading) {
      return IndicatorStates.Pending;
    }

    if (validDomainName === false || isError) {
      return IndicatorStates.Warning;
    }

    if (subDomain !== name || isIdle) {
      return IndicatorStates.Idle;
    }

    return IndicatorStates.Complete;
  };
}
